import { AfterViewInit, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role, User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User = new User();

  constructor(private router: Router, private jwt: JwtHelperService) {

  }



}
