import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APIResult, ApiToken } from '../models/api';
import { BaseData } from '../models/baseData';
import { Calculation } from '../models/calculation';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ApiService
{

  constructor(private http: HttpClient) { }

  //getter
  getUserById(id: number)
  {
    return this.http.get<User>(`${environment.apiUrl}/person?uid=${id}`);
  }

  getAllUsers()
  {
    return this.http.get<User[]>(`${environment.apiUrl}/person?search=`);
  }

  getBaseData()
  {
    return this.http.get<any>(`${environment.apiUrl}/baseData`);
  }

  getBaseDataByVersion(id: number | undefined)
  {
    return this.http.get<any>(`${environment.apiUrl}/baseData?version=${id}`);
  }

  getCalculation(id: number)
  {
    return this.http.get<any>(`${environment.apiUrl}/calc?ProcessNumber=${id}`);
  }

  getCalculationsList(filter: string)
  {
    return this.http.get<Calculation[]>(`${environment.apiUrl}/calc/list?filter=${filter}`);
  }

  getCSV(data: any)
  {
    return this.http.post(`${environment.apiUrl}/calc/export`, data, { responseType: 'blob' });
  }

  //setter

  login(email: string, password: string)
  {
    return this.http.post<ApiToken>(`${environment.apiUrl}/tokens`, { email: email, password: password });
  }

  saveBaseData(data: BaseData)
  {
    return this.http.post<APIResult>(`${environment.apiUrl}/baseData`, data);
  }

  saveCalculation(data: any)
  {
    return this.http.post<APIResult>(`${environment.apiUrl}/calc`, data);
  }

  deleteCalculation(id: number)
  {
    return this.http.delete<APIResult>(`${environment.apiUrl}/calc?ProcessNumber=${id}`);
  }

  registerUser(data: any)
  {
    return this.http.post<APIResult>(`${environment.apiUrl}/person`, data);
  }

  updateProfile(data: any)
  {
    return this.http.patch<APIResult>(`${environment.apiUrl}/person`, data);
  }

  deleteProfile(id: number)
  {
    return this.http.delete<APIResult>(`${environment.apiUrl}/person?uid=${id}`);
  }
}
