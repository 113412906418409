export enum Role
{
    appcologne = "appcologne",
    admin = "admin",
    salesManager = "salesManager",
    controller = "controller",
    seller = "seller",
}

export class User
{
    uid?: number;
    email?: string;
    deleted_at?: string;
    display_name?: string;
    logo?: string;
    logohash?: string;
    userrole?: Role;
}