<div class="container">

  <!-- <a routerLink="/staff/dashboard" class="logo"></a> -->
  <div>

    <!-- Login -->
    <mat-card class="login-form">
      <h2>Login</h2>


      <form [formGroup]="loginForm">
        <mat-form-field>
          <input matInput formControlName="email" placeholder="E-Mail">
        </mat-form-field>
        <mat-form-field>
          <input type="password" formControlName="password" matInput placeholder="Passwort">
        </mat-form-field>
        <br>
        <mat-toolbar style="background-color: transparent; padding: 0">
          <!-- <a style="font-size: small" [routerLink]="['/login', 'forgot-my-password']">Passwort vergessen?</a> -->
          <span class="spacer"></span>
          <button color="primary" mat-flat-button [disabled]="loginForm.invalid" (click)="login()">Einloggen</button>
        </mat-toolbar>
      </form>
    </mat-card>
  </div>
  <!-- <footer>
    <a routerLink="/imprint">Impressum</a>
  </footer> -->
</div>
