import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiToken, TokenData } from 'src/app/models/api';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  accountLocked = true;
  queryParams: any;

  constructor(private fb: FormBuilder, private api: ApiService, private router: Router, public route: ActivatedRoute, private jwt: JwtHelperService, private snack: MatSnackBar, private userService: UserService) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    const token = jwt.tokenGetter();
    if (token && !jwt.isTokenExpired(token)) router.navigate(["staff", "dashboard"])
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(result => {
      this.queryParams = result;

      if (result.activate && result.uid && result.token) {
        /* this.api.activateUser(result.uid, result.token).subscribe(result => {
          this.accountLocked = !result.Success ?? true;
        }); */
      }
    });
  }

  login() {
    const controls = this.loginForm.controls;


    this.api.login(controls.email.value, controls.password.value).subscribe(result => {

      if (result.access_token) {
        localStorage.setItem("access_token", result.access_token);

        //const tokenData = this.jwt.decodeToken<TokenData>(result.access_token);
        this.router.navigate(["staff", "dashboard"]);
      }

    }, err => {
      this.snack.open("Bei der Authentifizierung deines Accounts ist ein Fehler aufgetreten.");
    });
  }

  /* sendResetMail() {

    if (this.loginForm.controls.email.valid) {
      this.api.sendResetMail(this.loginForm.controls.email.value).subscribe(result => {
        if (result.Success) {
          this.snack.open("Ein Link zum Zurücksetzen des Passworts wurde an die angegebene E-Mail gesendet.");
          this.router.navigateByUrl("/login");
        } else {
          this.snack.open("Es ist ein unerwarter Fehler aufgetreten, bitte versuchen Sie es später erneut.");
        }
      });
    }
  }

  activateStepTwo() {
    this.router.navigateByUrl(`/login/set-new-password?uid=${this.queryParams.uid}&token=${this.queryParams.token}&eventUser=true`);
  }

  setNewPassword() {

    if (this.queryParams.eventUser) {
      this.api.activateEventUser(this.queryParams.uid, this.queryParams.token, this.loginForm.controls.password.value).subscribe(result => {
        if (result.Success) {
          this.snack.open("Passwort erfolgreich gesetzt. Du kannst dich nun mit deinen neuen Daten einloggen.");
          this.router.navigateByUrl("/login");
        } else {
          this.snack.open("Bei der Aktivierung deines Accounts ist ein Fehler aufgetreten.");
        }
      });
    } else {

      this.api.resetPassword(this.queryParams.uid, this.queryParams.token, this.loginForm.controls.password.value).subscribe(result => {

        if (result.Success) {
          this.snack.open("Passwort erfolgreich gesetzt. Du kannst dich nun mit deinen neuen Daten einloggen.");
          this.router.navigateByUrl("/login");
        } else {
          this.snack.open("Beim Setzen des Passwortes ist ein Fehler aufgetreten.");
        }
      });
    }
  } */
}