import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivateChild
{

  constructor(private jwt: JwtHelperService, private router: Router, private snack: MatSnackBar)
  {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.checkToken();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.checkToken();
  }

  checkToken(): boolean | UrlTree
  {
    const token = this.jwt.tokenGetter();
    if (token && !this.jwt.isTokenExpired(token)) return true;

    //logout the user
    localStorage.clear();
    this.snack.open("Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an.", "OK", { duration: -1 });
    return this.router.createUrlTree(["/login"]);
  }
}